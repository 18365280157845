import React from "react";
import VisruthaWork from "../../components/section/Visrutha";

const Visrutha = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <VisruthaWork />
      </div>
    </>
  );
};

export default Visrutha;
