import {
  visrutha1,
  visrutha2,
  visrutha3,
  visrutha4,
  visrutha5,
  visrutha6,
  visrutha7,
  visrutha8,
  visrutha9,
  visrutha10,
  visrutha11,
} from "../../assets";

const VisruthaWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#062627] pointer-events-none">
        <div className="flex flex-col items-center max-w-screen-xl mx-auto gap-10 pt-10 pb-10">
          <img src={visrutha1} className="h-full object-cover" />
          <img src={visrutha2} className="h-full object-cover" />
          <img src={visrutha3} className="h-full object-cover" />
          <img src={visrutha4} className="h-full object-cover" />
          <img src={visrutha5} className="h-full object-cover" />
          <img src={visrutha6} className="h-full object-cover" />
          <img src={visrutha7} className="h-full object-cover" />
          <img src={visrutha8} className="h-full object-cover" />
          <img src={visrutha9} className="h-full object-cover" />
          <img src={visrutha10} className="h-full object-cover" />
          <img src={visrutha11} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default VisruthaWork;
