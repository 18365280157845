import React from "react";
import ChaichaskaWork from "../../components/section/Chaichaska";

const Chaichaska = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <ChaichaskaWork />
      </div>
    </>
  );
};

export default Chaichaska;
