import React from "react";
import TmjWork from "../../components/section/TheMalabarJourney";

const Tmj = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <TmjWork />
      </div>
    </>
  );
};

export default Tmj;
