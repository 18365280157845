import { ll1, ll2, ll3, ll4, ll5, ll6, ll7, ll8, ll9 } from "../../assets";

const LahlahWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#f8261a] pointer-events-none">
        <div className="flex flex-col items-center max-w-screen-xl mx-auto gap-10 pt-10 pb-10">
          <img src={ll1} className="h-full object-cover" />
          <img src={ll2} className="h-full object-cover" />
          <img src={ll3} className="h-full object-cover" />
          <img src={ll4} className="h-full object-cover" />
          <img src={ll5} className="h-full object-cover" />
          <img src={ll6} className="h-full object-cover" />
          <img src={ll7} className="h-full object-cover" />
          <img src={ll8} className="h-full object-cover" />
          <img src={ll9} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default LahlahWork;
