import React from "react";
import ChaishalaWork from "../../components/section/Chaishala";

const Chaishala = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <ChaishalaWork />
      </div>
    </>
  );
};

export default Chaishala;
