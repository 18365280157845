import {
  tamaara1,
  tamaara10,
  tamaara11,
  tamaara12,
  tamaara13,
  tamaara14,
  tamaara15,
  tamaara16,
  tamaara2,
  tamaara3,
  tamaara4,
  tamaara5,
  tamaara6,
  tamaara7,
  tamaara8,
  tamaara9,
} from "../../assets";

const TamaaraWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="bg-[#ffffff] pointer-events-none">
        <div className="flex flex-col ">
          <img src={tamaara1} className="h-full object-cover" />
          <img src={tamaara2} className="h-full object-cover" />
          <img src={tamaara3} className="h-full object-cover" />
          <img src={tamaara4} className="h-full object-cover" />
        </div>

        <div class="columns-3 gap-0">
          <img src={tamaara5} className="h-full object-cover" />
          <img src={tamaara6} className="h-full object-cover" />
          <img src={tamaara7} className="h-full object-cover" />
        </div>

        <div class="columns-3 gap-0">
          <img src={tamaara9} className="h-full object-cover" />
          <img src={tamaara8} className="h-full object-cover" />
          <img src={tamaara10} className="h-full object-cover" />
        </div>

        <div className="flex flex-col ">
          <img src={tamaara11} className="h-full object-cover" />
        </div>

        <div class="columns-2 gap-0">
          <img src={tamaara12} className="h-full object-cover" />
          <img src={tamaara13} className="h-full object-cover" />
        </div>

        <div className="flex flex-col ">
          <img src={tamaara14} className="h-full object-cover" />
          <img src={tamaara15} className="h-full object-cover" />
          <img src={tamaara16} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default TamaaraWork;
