import {} from "../../assets";

const ChaichaskaWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#000000] pointer-events-none">
        <div className="flex flex-col items-center max-w-screen-xl mx-auto gap-10 pt-10 pb-10">
          Coming Soon
        </div>
      </div>
    </div>
  );
};

export default ChaichaskaWork;
