import {
  aukera1,
  aukera2,
  aukera3,
  aukera4,
  aukera5,
  aukera6,
  aukera7,
  aukera8,
  aukera9,
} from "../../assets";

const AukeraWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#fff] pointer-events-none">
        <div className="flex flex-col items-center">
          <img src={aukera1} className="h-full object-cover" />
        </div>

        <div className="columns-3 gap-1 pt-10 pb-0 max-w-screen-xl mx-auto">
          <img src={aukera1} className="h-full object-cover" />
          <img src={aukera2} className="h-full object-cover" />
          <img src={aukera3} className="h-full object-cover" />
        </div>

        <div className="columns-3 gap-1 pt-1 pb-0 max-w-screen-xl mx-auto">
          <img src={aukera4} className="h-full object-cover" />
          <img src={aukera5} className="h-full object-cover" />
          <img src={aukera6} className="h-full object-cover" />
        </div>

        <div className="columns-3 gap-1 pt-1 pb-20 max-w-screen-xl mx-auto">
          <img src={aukera7} className="h-full object-cover" />
          <img src={aukera8} className="h-full object-cover" />
          <img src={aukera9} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default AukeraWork;
