import React from "react";
import LahlahWork from "../../components/section/Lahlah";

const Lahlah = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <LahlahWork />
      </div>
    </>
  );
};

export default Lahlah;
