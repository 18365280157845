"use client";
import React, { useEffect, useRef, useState } from "react";
import data from "../data/portfolio/latestcases";
import mixitup from "mixitup";

function LatestCases() {
  const [activeFilter, setActiveFilter] = useState("All");
  const mixitupContainerRef = useRef(null);

  useEffect(() => {
    const initializeMixitup = () => {
      mixitup(mixitupContainerRef.current, {
        load: {
          sort: "order:asc",
        },
        animation: {
          duration: 700,
        },
        classNames: {
          block: "filter",
          elementFilter: "filter-btn",
          elementSort: "sort-btn",
        },
        selectors: {
          target: ".mix-item",
        },
      });
    };

    initializeMixitup();
  }, []);
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);

    // Remove 'active' class from all filter buttons
    document.querySelectorAll(".filter-btn").forEach((btn) => {
      btn.classList.remove("active");
    });

    // Add 'active' class to the clicked filter button, if it exists
    const clickedButton = document.querySelector(`[data-filter="${filter}"]`);
    if (clickedButton) {
      clickedButton.classList.add("active");
    }
  };

  return (
    <section className="tc-latest-cases-style1">
      <div className="container">
        <div className="content">
          <h1 className="h1 text-capitalize mb-90 js-splittext-lines mb-10">
            Latest Works
          </h1>
          <div className="cases">
            <div
              className="filter mb-10 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="links">
                <a
                  onClick={() => handleFilterClick("All")}
                  className={`filter-btn ${
                    activeFilter === "All" ? "active" : ""
                  }`}
                  href="#0"
                  data-filter="*"
                >
                  All
                </a>
                <a
                  href="#0"
                  onClick={() => handleFilterClick("Branding")}
                  className={`filter-btn ${
                    activeFilter === "Branding" ? "active" : ""
                  }`}
                  data-filter=".Branding"
                >
                  Branding
                </a>
                <a
                  href="#0"
                  onClick={() => handleFilterClick("UIUX")}
                  className={`filter-btn ${
                    activeFilter === "UIUX" ? "active" : ""
                  }`}
                  data-filter=".UIUX"
                >
                  UI/UX
                </a>
                <a
                  href="#0"
                  onClick={() => handleFilterClick("Web Design")}
                  className={`filter-btn ${
                    activeFilter === "Web Design" ? "active" : ""
                  }`}
                  data-filter=".Web Design"
                >
                  Web Design
                </a>
                <a
                  href="#0"
                  onClick={() => handleFilterClick("Store Design")}
                  className={`filter-btn ${
                    activeFilter === "Store Design" ? "active" : ""
                  }`}
                  data-filter=".Store Design"
                >
                  Store Design
                </a>

                <a
                  href="#0"
                  onClick={() => handleFilterClick("Package Design")}
                  className={`filter-btn ${
                    activeFilter === "Package Design" ? "active" : ""
                  }`}
                  data-filter=".Package Design"
                >
                  Package Design
                </a>

                <a
                  href="#0"
                  onClick={() => handleFilterClick("Print Design")}
                  className={`filter-btn ${
                    activeFilter === "Print Design" ? "active" : ""
                  }`}
                  data-filter=".Print Design"
                >
                  Print Design
                </a>

                <a
                  href="#0"
                  onClick={() => handleFilterClick("Infographics")}
                  className={`filter-btn ${
                    activeFilter === "Infographics" ? "active" : ""
                  }`}
                  data-filter=".Infographics"
                >
                  Infographics
                </a>

                <a
                  href="#0"
                  onClick={() => handleFilterClick("Illustrations")}
                  className={`filter-btn ${
                    activeFilter === "Illustrations" ? "active" : ""
                  }`}
                  data-filter=".Illustrations"
                >
                  Illustrations
                </a>

                <a
                  href="#0"
                  onClick={() => handleFilterClick("Ad Campaign")}
                  className={`filter-btn ${
                    activeFilter === "Ad Campaign" ? "active" : ""
                  }`}
                  data-filter=".Ad Campaign"
                >
                  Ad Campaign
                </a>
              </div>
            </div>
            <div
              className="cases-content wow fadeInUp slow"
              data-wow-delay="0.4s"
            >
              <div
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mixitup"
                ref={mixitupContainerRef}
              >
                {data.map((item, i) => (
                  <div key={i} className={`mix-item ${item.subTitle}`}>
                    <div className="case-card">
                      <a href={item.url} className="img" data-fancybox="cases">
                        <img src={item.img} alt="" className="img-cover" />
                      </a>

                      <div className="info">
                        <h3 className="title text-xl mb-2 uppercase">
                          <a href="#" className="hover-orange1">
                            {item.title}
                          </a>
                        </h3>
                        <div className="tags mb-30">
                          <a href="#"> {item.subTitle.split("<br />,")[0]} </a>
                          {item.subTitle.split("<br />")[1] && (
                            <a href="#">
                              {" "}
                              {item.subTitle.split("<br />,")[1]}{" "}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatestCases;
