import Button from "./Button";
import Section from "./Section";
import { ServiceUIUX } from "../assets";

const Service1 = () => {
  return (
    <Section
      className="pt-[12rem] min-h-screen max-h-min bg-[#ad218d]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >
      <div
        className="absolute top-0 right-0 h-screen w-1/3 bg-cover bg-right bg-no-repeat min-h-90 transition-all duration-12000 ease delay-7500 scale-100"
        style={{
          backgroundImage: `url(${ServiceUIUX})`,
        }}
      ></div>
      <div className="container relative">
        <div className="relative z-1 max-w-[50rem] mr-auto text-left">
          <h4 className="text-lg text-white">01</h4>
          <h1 className="h1 mb-6">UI/UX Design</h1>
          <p className="body-1 font-light mx-auto mb-6 text-n-2 lg:mb-8">
            UI/UX design focuses on creating intuitive, visually pleasing
            digital experiences that enhance user interaction and satisfaction.
            It merges creativity with usability principles to craft interfaces
            that are both aesthetically pleasing and functionally effective.
          </p>

          <div className="flex-col md:flex-row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-5 lg:gap-10 mb-10">
            <ul className="leading-8">
              <li>User Interface (UI) Design</li>
              <li>Research</li>
              <li>Visual Design</li>
              <li>Iterative Design</li>
            </ul>
            <ul className="leading-8">
              <li>User Experience (UX) Design</li>
              <li>Wireframing and Prototyping</li>
              <li>Usability Testing</li>
            </ul>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service1;
