import React from "react";
import AukeraWork from "../../components/section/Aukera";

const Aukera = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <AukeraWork />
      </div>
    </>
  );
};

export default Aukera;
