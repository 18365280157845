import {
  facebook,
  instagram,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
} from "../assets";

import ban1 from "../assets/hero/hero-background-1.webp";
import ban2 from "../assets/hero/hero-background-2.webp";
import ban3 from "../assets/hero/hero-background-3.webp";
import ban4 from "../assets/hero/hero-background-4.webp";
import ban5 from "../assets/hero/hero-background-5.webp";

export const navigation = [
  {
    id: "0",
    title: "Home",
    url: "/",
  },
  {
    id: "1",
    title: "About Us",
    url: "/about",
  },
  {
    id: "2",
    title: "Services",
    url: "/services",
  },
  {
    id: "3",
    title: "Works",
    url: "/works",
  },
  {
    id: "4",
    title: "Join Us",
    url: "/join-us",
  },
  {
    id: "5",
    title: "Contact",
    url: "/contact",
  },
];

export const companyLogos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo5,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
];

export const socials = [
  {
    id: "0",
    title: "Facebook",
    iconUrl: facebook,
    url: "https://www.facebook.com/people/Chorus-Design-Communications/61555247637948/",
  },
  {
    id: "1",
    title: "Instagram",
    iconUrl: instagram,
    url: "#",
  },
];

export const slides = [
  {
    backgroundUrl: ban1,
  },
  {
    backgroundUrl: ban2,
  },
  {
    backgroundUrl: ban3,
  },
  {
    backgroundUrl: ban4,
  },
  {
    backgroundUrl: ban5,
  },
];
