import {
  tmj1,
  tmj2,
  tmj3,
  tmj4,
  tmj5,
  tmj6,
  tmj7,
  tmj8,
  tmj9,
  tmj10,
  tmj11,
  tmj12,
  tmj13,
  tmj14,
  tmj15,
  tmj16,
  tmj17,
  tmj18,
  tmj19,
  tmj20,
  tmj21,
  tmj22,
  tmj23,
} from "../../assets";

const TmjWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#000] pointer-events-none">
        <div className="flex flex-col">
          <img src={tmj1} className="h-full object-cover" />
          <img src={tmj2} className="h-full object-cover" />
          <img src={tmj3} className="h-full object-cover" />
          <img src={tmj4} className="h-full object-cover" />
          <img src={tmj5} className="h-full object-cover" />
        </div>
        <div className="grid grid-flow-col">
          <img src={tmj7} className="h-full object-cover" />
          <img src={tmj8} className="h-full object-cover" />
        </div>

        <div className="flex flex-col bg-[#000] items-center">
          <img
            src={tmj9}
            className="h-full object-cover max-w-screen-lg pt-10 pb-10"
          />
          <img
            src={tmj10}
            className="h-full object-cover max-w-screen-lg pt-10 pb-10"
          />
          <img
            src={tmj11}
            className="h-full object-cover max-w-screen-lg pt-10 pb-10"
          />
          <img src={tmj12} className="h-full object-cover" />
          <img src={tmj13} className="h-full object-cover pt-10 pb-10" />
          <img src={tmj14} className="h-full object-cover" />
          <img src={tmj15} className="h-full object-cover" />
        </div>

        <div className="columns-3 gap-10 pt-10 pb-10">
          <img src={tmj16} className="h-full object-cover" />
          <img src={tmj17} className="h-full object-cover" />
          <img src={tmj18} className="h-full object-cover" />
        </div>

        <div className="flex flex-col bg-[#000] items-center">
          <img src={tmj19} className="h-full object-cover" />
          <img src={tmj20} className="h-full object-cover pt-10 pb-10" />
          <img src={tmj21} className="h-full object-cover" />
          <img src={tmj22} className="h-full object-cover" />
          <img src={tmj23} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default TmjWork;
