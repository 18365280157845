import { createBrowserRouter } from "react-router-dom";
import Root from "../Layout/Root";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Services from "../Pages/Services/services";
import Works from "../Pages/Works/works";
import Contact from "../Pages/Contact/Contact";
import JoinUs from "../Pages/Joinus/Joinus";
import Tamaara from "../Pages/Works/Tamaara";
import Tmj from "../Pages/Works/Tmj";
import MoEstasi from "../Pages/Works/Moestasi";
import Aukera from "../Pages/Works/Aukera";
import Lahlah from "../Pages/Works/Lahlah";
import Chaishala from "../Pages/Works/Chaishala";
import Chaichaska from "../Pages/Works/Chaichaska";
import Visrutha from "../Pages/Works/Visrutha";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/works",
        element: <Works />,
      },
      {
        path: "/works/tamaara",
        element: <Tamaara />,
      },
      {
        path: "/works/the-malabar-journal",
        element: <Tmj />,
      },
      {
        path: "/works/moestasi",
        element: <MoEstasi />,
      },
      {
        path: "/works/aukera",
        element: <Aukera />,
      },
      {
        path: "/works/lahlah",
        element: <Lahlah />,
      },
      {
        path: "/works/chaishala",
        element: <Chaishala />,
      },
      {
        path: "/works/chaichaska",
        element: <Chaichaska />,
      },
      {
        path: "/works/visrutha",
        element: <Visrutha />,
      },
      {
        path: "/join-us",
        element: <JoinUs />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);
