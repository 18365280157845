import Button from "./Button";
import Section from "./Section";
import { Seo } from "../assets";

const Service2 = () => {
  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem] min-h-screen bg-[#4287ec]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >
      <div
        className="absolute top-0 right-0 h-screen w-1/3 bg-contain bg-right bg-no-repeat min-h-90 transition-all duration-12000 ease delay-7500 scale-100 bg-[#fff]"
        style={{
          backgroundImage: `url(${Seo})`,
        }}
      ></div>
      <div className="container relative">
        <div className="relative z-1 max-w-[50rem] mr-auto text-left">
          <h4 className="text-lg text-white">02</h4>
          <h2 className="h1 mb-6">SEO & Social Media</h2>
          <p className="body-1 font-light mx-auto mb-6 text-n-1 lg:mb-8">
            SEO boosts website visibility on search engines. Social Media
            enhances brand engagement and drives traffic. Integrating both
            maximizes online presence and audience reach.
          </p>

          <div className="flex-col md:flex-row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-5 lg:gap-10 mb-10">
            <ul className="leading-8">
              <li>Search Engine Optimization</li>
            </ul>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service2;
