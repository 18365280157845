import React from "react";
import MoEstasiWork from "../../components/section/MoEstasi";

const MoEstasi = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <MoEstasiWork />
      </div>
    </>
  );
};

export default MoEstasi;
