import React from "react";
import JoinChorus from "../../components/JoinChorus";
import ContactForm from "../../components/ApplicationForm";

const JoinUs = () => {
  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
      <JoinChorus />
      <ContactForm />
    </div>
  );
};

export default JoinUs;
