import React from "react";
import TamaaraWork from "../../components/section/Tamaara";

const Tamaara = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <TamaaraWork />
      </div>
    </>
  );
};

export default Tamaara;
