import {
  chaishala1,
  chaishala2,
  chaishala3,
  chaishala4,
  chaishala5,
  chaishala6,
  chaishala7,
  chaishala8,
  chaishala9,
  chaishala10,
  chaishala11,
  chaishala12,
  chaishala13,
  chaishala14,
  chaishala15,
  chaishala16,
  chaishala17,
  chaishala18,
  chaishala19,
  chaishala20,
} from "../../assets";

const ChaishalaWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#fff] pointer-events-none">
        <div className="flex flex-col items-center max-w-screen-xl mx-auto gap-10 pt-10 pb-10">
          <img src={chaishala1} className="h-full object-cover" />
          <img src={chaishala2} className="h-full object-cover" />
          <img src={chaishala3} className="h-full object-cover" />
          <img src={chaishala4} className="h-full object-cover" />
          <img src={chaishala5} className="h-full object-cover" />
          <img src={chaishala6} className="h-full object-cover" />
          <img src={chaishala7} className="h-full object-cover" />
          <img src={chaishala8} className="h-full object-cover" />
          <img src={chaishala9} className="h-full object-cover" />
          <img src={chaishala10} className="h-full object-cover" />
          <img src={chaishala1} className="h-full object-cover" />
          <img src={chaishala12} className="h-full object-cover" />
          <img src={chaishala13} className="h-full object-cover" />
          <img src={chaishala14} className="h-full object-cover" />
          <img src={chaishala15} className="h-full object-cover" />
          <img src={chaishala16} className="h-full object-cover" />
          <img src={chaishala17} className="h-full object-cover" />
          <img src={chaishala18} className="h-full object-cover" />
          <img src={chaishala19} className="h-full object-cover" />
          <img src={chaishala20} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default ChaishalaWork;
