import {
  me1,
  me2,
  me3,
  me4,
  me5,
  me6,
  me7,
  me8,
  me9,
  me10,
  me11,
  me12,
  me13,
} from "../../assets";

const MoEstasiWork = () => {
  return (
    <div className="flex w-screen relative justify-center">
      <div className="w-full bg-[#e5e6e6] pointer-events-none">
        <div className="flex flex-col items-center">
          <img src={me1} className="h-full object-cover" />
        </div>
        <div className="flex flex-col items-center">
          <div className="max-w-screen-xl pt-10">
            <img src={me2} className="h-full object-cover" />
            <img src={me3} className="h-full object-cover" />
            <img src={me4} className="h-full object-cover" />
            <img src={me5} className="h-full object-cover" />
            <img src={me6} className="h-full object-cover" />
          </div>
        </div>

        <div className="grid grid-flow-col gap-10 pt-10 pb-10 max-w-screen-xl mx-auto">
          <img src={me7} className="h-full object-cover" />
          <img src={me8} className="h-full object-cover" />
        </div>

        <div className="flex flex-col items-center">
          <div className="max-w-screen-xl">
            <img src={me9} className="h-full object-cover" />
            <img src={me10} className="w-full object-cover" />
            <img src={me11} className="h-full object-cover" />
          </div>
        </div>

        <div className="grid grid-flow-col gap-10 pt-10 pb-10 max-w-screen-xl mx-auto">
          <img src={me12} className="h-full object-cover" />
          <img src={me13} className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default MoEstasiWork;
